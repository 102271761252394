export enum PermissionGuardName {
  ALL_ACCESS = 'ALL_ACCESS',
  COMPANY_ALL_ACCESS = 'COMPANY_ALL_ACCESS',
  MANAGE_BOOKINGS_ACCESS = 'MANAGE_BOOKINGS_ACCESS',
  VIEW_BOOKINGS_ACCESS = 'VIEW_BOOKINGS_ACCESS',
  CHECK_IN_ACCESS = 'CHECK_IN_ACCESS',
  CHECK_IN_REPORTING_ACCESS = 'CHECK_IN_REPORTING_ACCESS',
  ORGANISATION_ALL_ACCESS = 'ORGANISATION_ALL_ACCESS',
  TRUSTED_PARNER_ACCESS = 'TRUSTED_PARNER_ACCESS',
  TOUR_ATTENDANCE_ACCESS = 'TOUR_ATTENDANCE_ACCESS',
  INDIVIDUAL_SCHEDULE_ACCESS = 'INDIVIDUAL_SCHEDULE_ACCESS',
}
